import React from 'react';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql, Link } from 'gatsby';

const caseStudies = [
  {
    title: 'IPHA Conference 2025',
    imageSelector: 'iphaConference2025Image',
    link: '/our-work/ipha-conference-2025'
  },
  {
    title: 'Irish Heart Foundation Stroke Study Day',
    imageSelector: 'strokeStudyDayImage',
    link: '/our-work/irish-heart-foundation-stroke-study-day-2024'
  },
  {
    title: 'POI Janssen Event',
    imageSelector: 'janssenImage',
    link: '/our-work/precision-oncology-ireland-janssen-event'
  },
  {
    title: 'Parkinsons Virtual Conference',
    imageSelector: 'parkinsonsIrelandImage',
    link: '/our-work/virtual-event-parkinsons-media/'
  },
  {
    title: 'IPHA Conference 2024',
    imageSelector: 'iphaConference2024Image',
    link: '/our-work/ipha-conference-2024'
  },
  {
    title: 'IPHA Forum',
    imageSelector: 'iphaForumImage',
    link: '/our-work/online-engagement-campaign-ipha-forum'
  }
];
const AXCases = () => {
  const data = useStaticQuery(graphql`
    query {
      iphaConference2024Image: file(relativePath: { eq: "caseStudyImgs/ipha-conference.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            placeholder: TRACED_SVG
            formats: AUTO
            quality: 90
          )
        }
      }
      janssenImage: file(relativePath: { eq: "caseStudyImgs/janssen.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            placeholder: TRACED_SVG
            formats: AUTO
            quality: 90
          )
        }
      }
      iphaConference2025Image: file(
        relativePath: { eq: "caseStudyImgs/ipha-conference-2025.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            placeholder: TRACED_SVG
            formats: AUTO
            quality: 90
          )
        }
      }
      strokeStudyDayImage: file(relativePath: { eq: "caseStudyImgs/stroke-study-day.png" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            placeholder: TRACED_SVG
            formats: AUTO
            quality: 90
          )
        }
      }
      parkinsonsIrelandImage: file(relativePath: { eq: "caseStudyImgs/parkinsons-ireland.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            placeholder: TRACED_SVG
            formats: AUTO
            quality: 90
          )
        }
      }
      iphaForumImage: file(relativePath: { eq: "caseStudyImgs/ipha-forum.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 800
            placeholder: TRACED_SVG
            formats: AUTO
            quality: 90
          )
        }
      }
    }
  `);

  const caseStudyImages = caseStudies.map((caseStudy) => {
    return getImage(data[caseStudy.imageSelector]);
  });

  return (
    <Cases
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.25 }}>
      {caseStudies.map((caseStudy, index) => (
        <Case key={index} to={caseStudy.link}>
          <GatsbyImage image={caseStudyImages[index]} alt={caseStudy.title} />
          <h4>{caseStudy.title}</h4>
          <h5>{caseStudy.company}</h5>
        </Case>
      ))}
    </Cases>
  );
};

const Cases = styled(motion.div)`
  transition: 0.75s;
  grid-column: 1/6;
  display: flex;
  flex-direction: column;
  grid-column-gap: 1.25rem;
  grid-row-gap: 2.5rem;
  margin: 0 auto;
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    max-width: 600px;
  }
  @media (min-width: 1150px) {
    display: grid;
    grid-column: 2/12;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    max-width: 1180px;
  }

`;

const Case = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  &:nth-of-type(n + 4) {
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  h4,
  h5 {
    font-size: 1.25rem !important;
    line-height: 1.25rem;
    text-align: left;
    @media (min-width: 1150px) {
      font-size: 1.5rem;
      line-height: 1.25rem;
    }
  }
  h4 {
    color: var(--ax-pink);
    margin-top: 1rem;
    margin-bottom: 0.55rem !important;
    line-height: 1em;
  }
  h5 {
    color: black;
    font-weight: normal;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
`;

export default AXCases;
