import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SectionTitle } from 'components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { motion, AnimatePresence } from 'framer-motion';
import { useLocation } from '@reach/router';
import { useWindowSize } from 'hooks';
import PrevArrowSVG from 'images/shared/PrevArrow';
import { wrap } from '@popmotion/popcorn';

const services = [
  {
    title: 'Events & Conferences',
    summary:
      'Expert design, planning and production management of all types of in-person, virtual & hybrid events and conferences by our specialist in house Agency X team.',
    link: '/services/healthcare-events-and-conferences/',
    list: [
      'Insights & Strategy Development',
      'Campaign Creative & Design',
      'Project Management',
      'Campaign Analytics'
    ]
  },
  {
    title: 'Omnichannel Strategies',
    summary:
      'We can work with you on all aspects of omnichannel marketing — developing the strategic approach, the content that fully engages HCPs at each touchpoint, and the technology that delivers it.',
    link: '/services/healthcare-omnichannel-strategies',
    list: [
      'Unique Team Engagement',
      'Online Team Collaboration',
      'Team Building Gamification',
      'Team Created Marketing Strategy'
    ]
  },
  {
    title: 'Design & Development',
    summary:
      'Providing a full range of creative design and development services. From initial concepts to full design production, our studio provide all things creative, from print POS & packaging to bespoke UI/UX websites and everything in between...',
    link: '/services/healthcare-brand-and-design',
    list: [
      'Original Creative Concepts',
      'Web Design & Development',
      'App Development',
      'Multimedia Creative Design'
    ]
  },
  {
    title: 'Digital & Social',
    summary:
      'Targeted digital content and media strategies that ensure your audience is being served the right content on the right platform, and always with fully measurable campaign analytics and reporting.',
    link: '/services/healthcare-digital-and-social-campaigns',
    list: [
      'Online Audience Engagement',
      'Branded Online Studio',
      'Live/Prerecorded Shows & Events',
      'Post Event Communications'
    ]
  }
];

const serviceVariants = {
  hover: {
    cursor: 'pointer',
    scale: 1.02,
    transition: {
      type: 'tween',
      ease: 'easeInOut',
      duration: 0.2
    }
  }
};

const variants = {
  enter: (direction) => {
    return {
      x: direction > 0 ? 100 : -100,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 100 : -100,
      opacity: 0
    };
  }
};

const ServicesOverview = () => {
  const { windowWidth } = useWindowSize();
  const { pathname } = useLocation();
  const [[page, direction], setPage] = useState([0, 0]);
  const paginate = (dir) => {
    setPage([page + dir, dir]);
  };
  const index = wrap(0, services.length, page);

  return (
    <OuterContainer id="services">
      <div>
        {pathname === '/' && (
          <SectionTitle style={{ margin: '-0.1rem 0 0.05rem' }}>Services</SectionTitle>
        )}

        <Mobile>
          <Services>
            <Container
              key={page}
              id={services[index]}
              alt="slide"
              custom={direction}
              variants={variants}
              initial="enter"
              animate="center"
              exit="exit"
              transition={{
                duration: 0.5
              }}
              onPan={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  paginate(1);
                } else if (swipe > swipeConfidenceThreshold) {
                  paginate(-1);
                }
              }}>
              <AnimatePresence initial={false}>
                <motion.div key={services[index].title}>
                  <AnchorLink
                    to={services[index].link}
                    title={services[index].title}
                    aria-label={services[index].title}
                    style={{ textDecoration: 'none' }}
                    id={`see-more-${services[index].title
                      .toLowerCase()
                      .replaceAll(' ', '-')
                      .replaceAll('&', 'and')}`}>
                    <Service
                      variants={serviceVariants}
                      id={`see-more-${services[index].title
                        .toLowerCase()
                        .replaceAll(' ', '-')
                        .replaceAll('&', 'and')}`}>
                      <motion.h3 variants={serviceVariants}>
                        {index === 2 ? (
                          <h3>
                            Digital &
                            <br />
                            Social
                          </h3>
                        ) : (
                          services[index].title
                        )}
                      </motion.h3>

                      {pathname === '/services/' ? (
                        <ul>
                          {services[index].list.map((listItem) => (
                            <li key={listItem}>{listItem}</li>
                          ))}
                        </ul>
                      ) : (
                        <p>{services[index].summary}</p>
                      )}
                      <SeeMore
                        variants={serviceVariants}
                        id={`see-more-${services[index].title
                          .toLowerCase()
                          .replaceAll(' ', '-')
                          .replaceAll('&', 'and')}`}>
                        See More
                      </SeeMore>
                    </Service>
                  </AnchorLink>
                </motion.div>
              </AnimatePresence>
            </Container>
            <ButtonWrapper>
              <ButtonBack type="button" aria-label="Previous Service" onClick={() => paginate(-1)}>
                <PrevArrow />
              </ButtonBack>
              <ButtonNext type="button" aria-label="Next Service" onClick={() => paginate(1)}>
                <NextArrow />
              </ButtonNext>
            </ButtonWrapper>
          </Services>
        </Mobile>
        <Desktop>
          <Services>
            {services.map((service, i) => (
              <motion.div
                key={service.title}
                variants={serviceVariants}
                whileHover="hover"
                whileTap="tap"
                layout>
                <AnchorLink
                  to={service.link}
                  title={service.title}
                  style={{ textDecoration: 'none' }}
                  id={`see-more-${service.title
                    .toLowerCase()
                    .replaceAll(' ', '-')
                    .replaceAll('&', 'and')}`}>
                  <Service
                    variants={serviceVariants}
                    id={`see-more-${service.title
                      .toLowerCase()
                      .replaceAll(' ', '-')
                      .replaceAll('&', 'and')}`}>
                    <motion.h3 variants={serviceVariants}>
                      {i === 3 ? (
                        <h3>
                          Digital &
                          <br />
                          Social
                        </h3>
                      ) : (
                        service.title
                      )}
                    </motion.h3>
                    {pathname === '/services/' ? (
                      <ul>
                        {service.list.map((listItem) => (
                          <li key={listItem}>{listItem}</li>
                        ))}
                      </ul>
                    ) : (
                      <p>{service.summary}</p>
                    )}
                    <SeeMore
                      variants={serviceVariants}
                      id={`see-more-${service.title
                        .toLowerCase()
                        .replaceAll(' ', '-')
                        .replaceAll('&', 'and')}`}>
                      See More
                    </SeeMore>
                  </Service>
                </AnchorLink>
              </motion.div>
            ))}
          </Services>
        </Desktop>
      </div>
    </OuterContainer>
  );
};

const swipeConfidenceThreshold = 100;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const OuterContainer = styled.section`
  background-color: var(--ax-beige);
  > div {
    padding-top: 2.05rem;
    grid-column: 2 / 4;
    max-width: 1577px;
    margin: 0 auto;
    width: 100%;

    /* The carousel adds unwanted padding around the slides. Hiding the overflow solves this. */
    overflow: hidden;

    @media screen and (min-width: 48rem) {
      grid-column: 2 / 3;
      padding: 6rem 0;
      overflow: visible;
    }

    @media screen and (min-width: 62.5rem) {
      padding: 4rem 0;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: initial;
  bottom: initial;
  right: 0.625rem !important;
  width: 7.188rem;
  top: -1rem !important;
  z-index: 2;
  height: 3rem;
`;

const ButtonStyle = css`
  align-items: center;
  background-color: var(--ax-gold);
  border-radius: 0.125rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  outline: none;
  transition: background-color 150ms ease-in-out;
  width: 3rem;

  &[disabled] {
    background-color: var(--ax-grey);
  }

  &:active {
    background-color: #000;
    transition: background-color 75ms ease-in-out;
  }
`;

const ButtonBack = styled.button`
  ${ButtonStyle}
`;

const ButtonNext = styled.button`
  ${ButtonStyle}
`;

const PrevArrow = styled(PrevArrowSVG)`
  height: 0.938rem;
  width: 1.688rem;
`;

const NextArrow = styled(PrevArrow)`
  transform: scaleX(-1);
`;

const SeeMore = styled(motion.div)`
  align-items: center;
  border-radius: 0.125em;
  border: 0.063em solid var(--ax-gold);
  color: var(--ax-gold);
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  height: 3em;
  justify-content: center;
  text-transform: uppercase;
  width: 10.375em;

  @media screen and (min-width: 48rem) {
    border: none;
    bottom: 0;
    color: #000;
    display: block;
    font-weight: 700;
    height: unset;
    margin-bottom: 0.3em;
    pointer-events: none;
    position: absolute;
    width: unset;
  }
`;

const Services = styled.div`
  margin: 0 auto;
  position: relative;
  max-width: 100%;
  a {
    text-decoration: none;
  }

  @media screen and (max-width: 39.75rem) {
    max-width: 28.125rem;
  }

  @media screen and (min-width: 48rem) {
    display: grid;
    grid-gap: 95px 86px;
    grid-template-columns: repeat(2, 250px);
    grid-template-rows: 300px 300px;
    justify-content: center;
    padding-top: 3.7rem;
  }

  @media screen and (min-width: 80rem) {
    grid-template-rows: 300px;
    grid-template-columns: repeat(4, minmax(auto, 250px));
    justify-content: space-between;
    grid-gap: 0;
    /* max-width: 73.75rem; */
    width: 100%;
  }
`;

const Service = styled(motion.div)`
  background-color: #fff;
  height: 100%;
  margin-top: 1.1rem;
  max-height: 26.375rem;
  padding: 5rem 1.3rem 1.438rem 1.438rem;
  width: calc(100% - 1.25rem);

  ul {
    li {
      font-size: 1rem;
      line-height: 2em;
    }
  }

  @media screen and (min-width: 351px) {
    max-height: 30rem;
  }

  svg {
    margin-bottom: 1.28rem;
    width: 2.5rem;
    height: 2.313rem;

    g {
      transition: stroke 200ms ease-in-out;
      stroke-width: 1.25px;
    }
  }
  h3 {
    color: var(--ax-gold);
    margin-bottom: 0.6em;
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: 0.01em;
    text-wrap: wrap;
    transition: 0.3s;
    svg {
      width: auto;
      height: 0.9rem;
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }

  p {
    color: #000;
    font-size: 0.875rem;
    line-height: 1.429em;
    margin-bottom: 3.3em;
    max-width: 92.5%;
  }

  ul {
    list-style-type: none;
    font-size: 1rem;
    line-height: 1.375em;
    color: #000;
    margin-bottom: 1.75em;
  }
  ${SeeMore} {
    transition: 0.3s;
  }
  &:hover {
    svg g {
      stroke: var(--ax-gold);
    }
    h3 {
      color: var(--ax-gold);
      font-weight: 900;
    }
    ${SeeMore} {
      color: var(--ax-gold);
    }
  }

  &:not(:hover) {
    svg g {
      stroke: #000;
    }

    ul {
      font-weight: 400;
    }
  }

  @media screen and (min-width: 48rem) {
    background-color: transparent;
    margin-top: 0;
    padding: 0;
    width: 100%;
    position: relative;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-bottom-color: #000;
    transition: 0.3s;
    &:hover {
      border-bottom: 2px solid var(--ax-gold);
    }

    svg {
      margin-bottom: 1.2rem;
    }

    h3 {
      font-weight: 100;
      margin-bottom: 1rem;
      color: #000;
      transition: font-weight 0.25s ease-in-out;
    }

    p {
      max-width: 100%;
    }

    ul {
      margin-bottom: 0;
    }
  }
`;

const Container = styled(motion.div)`
  width: 100%;
  height: auto;
  position: relative;
`;

const Mobile = styled.div`
  display: block;
  @media (min-width: 48rem) {
    display: none;
  }
`;

const Desktop = styled.div`
  display: none;
  @media (min-width: 48rem) {
    display: block;
  }
`;

export default ServicesOverview;
