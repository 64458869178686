import React, { useEffect, useState } from 'react';
import { Header, Footer, Transition, ContactUsButton } from 'components';
import { AnimatePresence, motion } from 'framer-motion';
import { GlobalStyle } from 'shared_styles';
import { isMobileOnly } from 'react-device-detect';
import { useWindowSize, useIsomorphicLayoutEffect } from 'hooks';
import styled, { keyframes, css } from 'styled-components';
import ScrollTrigger from 'gsap/ScrollTrigger';
import ScrollSmoother from 'gsap/ScrollSmoother';
import { gsap } from 'gsap';
import { SmootherContext } from 'context';
import '../../fonts/fonts.css';

function Layout({ children, location }) {
  const [smoother, setSmoother] = useState();
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const [showWebsiteOverlay, setShowWebsiteOverlay] = useState(false);

  useIsomorphicLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

    const _smoother = ScrollSmoother.create({
      smooth: 1,
      normalizeScroll: true, // prevents address bar from showing/hiding on most devices, solves various other browser inconsistencies
      ignoreMobileResize: true, // skips ScrollTrigger.refresh() on mobile resizes from address bar showing/hiding
      effects: true
    });

    setSmoother(_smoother);
  }, []);

  const { pathname } = location;
  const { windowWidth } = useWindowSize();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setUserHasScrolled(true);
      } else {
        setUserHasScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [pathname]);

  useEffect(() => {
    if (!showWebsiteOverlay && userHasScrolled) {
      (function waitForCookieYes() {
        if (window.getCkyConsent) {
          const { isUserActionCompleted } = window.getCkyConsent();
          if (!isUserActionCompleted) {
            setShowWebsiteOverlay(true);
            document.addEventListener('cookieyes_consent_update', () =>
              setShowWebsiteOverlay(false)
            );
          }
        } else {
          setTimeout(waitForCookieYes, 50);
        }
      })();
    }
  }, [userHasScrolled]);

  return (
    <>
      <SmootherContext.Provider value={smoother}>
        <GlobalStyle />
        <Header />
        <div id="smooth-content">
          <Wrapper
            id="top"
            pathname={pathname}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ delay: 0 }}>
            <Transition pathname={pathname}>
              <main>{children}</main>
            </Transition>
            <AnimatePresence>
              {!isMobileOnly && pathname !== '/contact/' && pathname !== '/logobook/' && (
                <ContactUsButton />
              )}
            </AnimatePresence>
            {pathname !== '/contact/' || (pathname === '/contact/' && windowWidth < 1200) ? (
              <Footer />
            ) : null}
          </Wrapper>
          {process.env.NODE_ENV !== 'development' && (
        <AnimatePresence>{showWebsiteOverlay && <WebsiteOverlay />}</AnimatePresence>
      )}
        </div>
      </SmootherContext.Provider>
    </>
  );
}

const fadeIn = keyframes`
  to {
    opacity: 1;
  }
`;

const WebsiteOverlay = styled(motion.div).attrs({
  initial: {
    opacity: 0
  },
  animate: {
    opacity: 1
  },
  exit: {
    opacity: 0
  },
  transition: {
    duration: 0.5
  }
})`
  background-color: rgba(000, 000, 000, 0.4);
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 7;
`;

const Wrapper = styled(motion.div)`
  background-color: ${({ pathname }) =>
    pathname === '/logobook/' ? 'var(--ax-black)' : 'var(--ax-beige)'};
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative !important;

  main > section {
    ${({ pathname }) =>
      pathname === '/' &&
      css`
        opacity: 0;
        animation: ${fadeIn} 750ms ease-in-out 0ms forwards;
        @media (min-width: 450px) {
          animation: ${fadeIn} 500ms ease-in-out 1250ms forwards;
        }
      `}

    display: grid;
    grid-template-columns:
      1.25rem
      1fr
      1.25rem;

    @media screen and (min-width: 48rem) {
      grid-template-columns:
        6.25%
        1fr
        6.25%;
    }

    @media screen and (min-width: 90rem) {
      grid-template-columns:
        9%
        1fr
        9%;
    }
  }

  main {
    position: relative;
    grid-area: 1 / 1 / -1 / -1;
  }
`;

export default Layout;
